import React from "react"

import Layout from "../../components/Layout"
import TitleBlock from "../../components/TitleBlock"

import "../../styles/global.scss"
import styles from "./notFound.module.scss"

import TitleImg from "../../assets/images/pages/notFound/TitleImg.svg"
import useTranslation from "../../hooks/useTranslation"

const Page404 = () => {
  const { t } = useTranslation("page404")

  return (
    <Layout>
      <TitleBlock
        className={styles.mainBox}
        miniTitle=""
        title={t("title")}
        image={<TitleImg className={styles.titleImg} />}
      >
        {t("text")}
      </TitleBlock>
    </Layout>
  )
}

export default Page404
